<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					
					<div class="custom-btns-container">
                        <div class="filters-container">

                        
                            <alternativeAutocompleteOne
                                class="filters-container-item-1"
                                :items="businesses"
                                :valueInput="businessId"
                                :loading="businessLoading"
                            
                                @valueChange="(v) => {businessId = v}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Empresa'"
                                classesContainer="width-auto"
                            ></alternativeAutocompleteOne>
                            
                            <alternativeAutocompleteOne
                                class="filters-container-item-2"
                                :items="types"
                                :valueInput="typeSelected"
                                
                            
                                @valueChange="(v) => {typeSelected = v; selectedWeek = ''}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Tipo'"
                                classesContainer="width-auto"
                            ></alternativeAutocompleteOne>
                            
                            
                            <alternativeAutocompleteOne
                                class="filters-container-item-3"
                                :items="years"
                                :valueInput="selectedYear"
                                
                            
                                @valueChange="(v) => {selectedYear = v; selectedWeek = ''}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Año'"
                                classesContainer="width-auto"
                            ></alternativeAutocompleteOne>
                            
                            <alternativeAutocompleteOne
                                class="filters-container-item-4"
                                :items="weeks"
                                :valueInput="selectedWeek"
                                
                            
                                @valueChange="(v) => {selectedWeek = v}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Periodo'"
                                classesContainer="width-auto"
                            ></alternativeAutocompleteOne>
                        </div>
                        <!-- <v-spacer></v-spacer> -->
                        <div class="btns-container" :key="updateBtns">
                            <iconButton
                                v-permission="'payroll_payments.create'"
                                class="mr-1"
                                :props="{
                                    disabled: true,
                                    color:'primary',
                                    icon:'mdi-email-outline',
                                    iconColor:'white',
                                    height:'35',
                                    click: () => {sendMail()}
                                }"
                            ></iconButton>
                            <iconButton
                                v-permission="'payroll_payments.delete'"
                                class="mr-1"
                                :props="{
                                color:'primary',
                                icon:'mdi-history',
                                iconColor:'white',
                                height:'35',
                                disabled: lockButtons,
                                click: () => {cancelMulti()},
                                loading:loadingMultiCancel
                                }"
                            ></iconButton>
                            <primary-button v-permission="'payroll_payments.create'" :props="payBtnData" class="mr-1"></primary-button>
                            <iconButton
                                v-permission="'payroll_payments.create'"
                                :props="{
                                color:'primary',
                                icon:'mdi-progress-upload',
                                iconClass: '',
                                iconColor:'white',
                                height:'35',
                                click: () => {openUploadPayrollModal()}
                                }"
                            ></iconButton>
                            <iconButton
                                class="ml-1"
                                v-permission="'payroll_payments.show'"
                                :props="{
                                color:'primary',
                                icon:'mdi-magnify',
                                iconClass: '',
                                iconColor:'white',
                                height:'35',
                                click: () => {openSearchPayrollModal()}
                                }"
                            ></iconButton>
                        </div>
                        
                    </div>
					
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            
                            <v-card-text >
                                <datatable :options="options" @handleOptions="handleOptions" :props="tableProps" @method_handler="method_handler" :valueInput="tableSelected" @valueChange="(v) => {tableSelected = v}" >
                                    <template v-slot:item.signed_salary="props">
                                        <span :class="`${props.item.id != null ? 'green--text text--darken-1 font-weight-bold' : ''}`">{{props.item.signed_salary}}</span>
                                    </template>
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
            <a href="/files/format_example.csv" download class="d-none" ref="downloadFormat">DOWNLOAD</a>


		</v-card>

        <!-- UPLOAD FILE MODAL -->
        <modal :props="modalPayFileProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody">
                <p class="font-weight-bold text-center text-subtitle-1">APLICACIÓN DEL PAGO DE LA NÓMINA PARA EL PERIODO {{typeTitle}} DEL {{periodStartTitle}} A {{periodEndTitle}} EN {{businessTitle}}</p>
				<v-form ref="form" @submit.prevent="save">
                    <v-row>
                        <v-col class="py-2" cols="12" md="9">
                            <fileInputForm
                                :accept="'.csv,.CSV'"
                                label="Archivo de nómina"
                                :multiple="false"
                                :valueInput="file"
                                :url="path"
                                :rule="rules.file_required"
                                @valueChange="(v) => {file = v; delete serverErrors.file}"
                                :errorMessages="serverErrors.file"
                                v-on:update:url="path = $event"
                            ></fileInputForm>
                        </v-col>
                        
                        <v-col class="upload-btn">
                            <div class="Payroll-modalPay-top-buttons-container">
                                <iconButton
                                    class="mr-2"
                                    v-permission="'payroll_payments.create'"
                                    :props="{
                                    color:'primary',
                                    icon:'mdi-information-outline',
                                    iconClass: '',
                                    iconColor:'primary',
                                    height:'35',
                                    outline: true,
                                    click: () => {openFormatModal()}
                                    }"
                                ></iconButton>
                                <primaryButton
                                    :props="uploadPayFileModal"
                                    class=""
                                ></primaryButton>
                            </div>
                           
                        </v-col>
                    </v-row>
                    
                </v-form>
                <v-row>
                    <v-col>
                        <span v-if="tableUploadProps.items.length > 0" class="ml-2">{{tableUploadProps.items.length}} Registros en este archivo</span>
                        <datatable  :props="tableUploadProps" @methodHandler="method_handler" >
                           
                        </datatable>
                    </v-col>
                </v-row>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="3">
						<secondaryButton
						:props="cancelPayFileModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="3">
						<primaryButton
						:props="acceptPayFileModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


        <!-- FORMAT DOWNLOAD -->
        <modal :props="modalFormatProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
                <span>1.- Conserva todos los encabezados en el orden presentado y sin modificarlos.</span><br>
                <span>2.- Llena la información de acuerdo a los campos solicitado y no utilices comas en ninguno de los campos.</span><br>
                <span>3.- El archivo debe estar en formato CSV</span><br>
                <span>4.- Las cantidades en integradora y fiscal pueden llevar hasta 2 decimales</span><br>
                <span>5.- A continuación se muestra una línea de ejemplo acorde al formato</span><br>
                <datatable :props="tableFormatProps" @method_handler="method_handler">
                    
                </datatable>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" >
					<v-col cols="12" sm="3">
						<secondaryButton
                            :props="donwloadFormatModal"
						></secondaryButton>
					</v-col>
                    
					<v-col cols="12" sm="3"  class="mr-sm-4">
						<primaryButton
						:props="acceptFormatModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


        <!-- modalUploadPayrollProps -->
        <modal :props="modalUploadPayrollProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody">
				<v-form ref="formUploadPayroll" @submit.prevent="save" class="px-0">
                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                label="Empresa"
                                :valueInput="formPayrollUpload.business_id"
                                :rule="rules.required"
                                :items="businesses"
                                :errorMessages="serverErrors.business_id"
                                @valueChange="(v) => {formPayrollUpload.business_id = v; delete serverErrors.business_id}"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                label="Periodo de pago"
                                :valueInput="formPayrollUpload.payroll_type"
                                :rule="rules.required"
                                :items="types"
                                :errorMessages="serverErrors.payroll_type"
                                @valueChange="(v) => {formPayrollUpload.payroll_type = v; getWeeksUploadPayroll(); formPayrollUpload.selectedWeek = ''; delete serverErrors.payroll_type}"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                class="filters-container-item-4"
                                :items="weeksUploadPayroll"
                                :valueInput="formPayrollUpload.selectedWeek"
                                :rule="rules.required"
                            
                                @valueChange="(v) => {formPayrollUpload.selectedWeek = v}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Periodo'"
                                classesContainer="width-auto"
                            ></autocompleteOneForm>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                label="Tipo de recibo"
                                :valueInput="formPayrollUpload.type"
                                :rule="rules.required"
                                :items="receipts"
                                :errorMessages="serverErrors.type"
                                @valueChange="(v) => {formPayrollUpload.type = v; delete serverErrors.type}"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>
                     
                           

                    <v-row>
                        <v-col class="py-2 " cols="12">
                            <fileInputForm
                                :accept="'.pdf,.PDF'"
                                label="Archivo"
                                :multiple="true"
                                :valueInput="formPayrollUpload.file"
                                :url="path"
                                :rule="rules.file_required"
                                @valueChange="(v) => {formPayrollUpload.file = v; delete serverErrors.file}"
                                :errorMessages="serverErrors.file"
                                v-on:update:url="path = $event"
                            ></fileInputForm>
                        </v-col>
                    </v-row>
                    
                </v-form>
                
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" class="px-0 px-sm-2">
					<v-col cols="12" sm="3">
						<secondaryButton
						:props="cancelUploadPayrollModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="3" class="">
						<primaryButton
						:props="acceptUploadPayrollModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- UPLOAD PAYROLL RESULT MODAL-->
        <modal :props="modalUploadResultProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody">

                <p class="pb-0">
                    Se han cargado <span class="primary--text font-weight-bold">{{uploadResultCount}}</span> recibos de nómina {{typeSelected}} de tipo {{uploadResultType}}
                    para {{uploadResultBusinessSelected}} en el periodo {{uploadResultStart}} - {{uploadResultEnd}}
                </p>

                <datatable :props="tableUploadResultProps" @method_handler="method_handler">
                    <template v-slot:[`item.has_paycheck`]="{ item }">
                        <v-icon size="20" v-if="item.has_paycheck" color="success" class="``">mdi-check-circle-outline</v-icon>
                        <v-icon size="20" v-if="item.has_paycheck == false" color="red" class="``">mdi-circle-outline</v-icon>
                    </template> 

                </datatable>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" class="px-0 px-sm-2">
					<v-col cols="12" sm="3">
						<secondaryButton
						:props="cancelUploadResultModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="3" class="">
						<primaryButton
						:props="acceptUploadResultModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


        <!-- SEARCH PAYROLL -->
        <modal :props="modalSearchPayrollProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
                <v-form ref="formSearchPayroll" @submit.prevent="searchPayroll" class="px-1">
                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                label="Empresa"
                                :valueInput="formPayrollSearch.business_id"
                                :rule="rules.required"
                                :items="businesses"
                                :errorMessages="serverErrors.business_id"
                                @valueChange="(v) => {formPayrollSearch.business_id = v; delete serverErrors.business_id}"
                            ></autocompleteOneForm>
                        </v-col>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                label="Periodo de pago"
                                :valueInput="formPayrollSearch.payroll_type"
                                :rule="rules.required"
                                :items="types"
                                :errorMessages="serverErrors.payroll_type"
                                @valueChange="(v) => {formPayrollSearch.payroll_type = v; getWeeksSearchPayroll(); formPayrollSearch.selectedWeek = ''; delete serverErrors.payroll_type}"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-2">
                            <autocompleteOneForm
                                class="filters-container-item-4"
                                :items="weeksSearchPayroll"
                                :valueInput="formPayrollSearch.selectedWeek"
                                :rule="rules.required"
                            
                                @valueChange="(v) => {formPayrollSearch.selectedWeek = v}"
                                @onChange="(v) => {}"
                            
                                :label="'Periodo'"
                                classesContainer="width-auto"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <primaryButton
                            :props="acceptSearchPayrollModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>


                </v-form>


                <datatable :props="tableSearchPayloadProps" @method_handler="method_handler">
                    <template v-slot:[`item.has_fiscal_paycheck`]="{ item }">
                        <v-icon size="20" v-if="item.has_fiscal_paycheck" color="success" :class="`Payroll-modalSearch-icon-mov`">mdi-check-circle-outline</v-icon>
                        <v-icon size="20" v-if="item.has_fiscal_paycheck == false" color="red" class="``">mdi-circle-outline</v-icon>
                        <v-icon size="20" v-if="item.has_fiscal_paycheck" color="success" class="``" @click="openUrl(item.fiscal_paycheck)">mdi-file-document-outline</v-icon>

                    </template> 

                    <template v-slot:[`item.has_integradora_paycheck`]="{ item }">
                        <v-icon size="20" v-if="item.has_integradora_paycheck" color="success" :class="`Payroll-modalSearch-icon-mov`">mdi-check-circle-outline</v-icon>
                        <v-icon size="20" v-if="item.has_integradora_paycheck == false" color="red" class="``">mdi-circle-outline</v-icon>
                        <v-icon size="20" v-if="item.has_integradora_paycheck" color="blue" class="``" @click="openUrl(item.integradora_paycheck)">mdi-file-document-outline</v-icon>

                    </template> 
                </datatable>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				
			</template> 
		</modal>
     
	</div>
</template>
<script>
import Object2Form from '../../../helpers/Object2Form';
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';

export default {

    computed:{
        typeTitle:function(){
            return this.typeSelected;
        },
        periodStartTitle:function(){
            try {
                const arrDates = this.selectedWeek.split("|");
                // let stDat = new Date(arrDates[0]);
                // this.businesses.re
                // // let dateArray = new Array();
                // const year = stDat.getFullYear();
                // let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                //     stDat
                // );
                // let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(stDat);
                // let result = `${da}/${mo}/${year}`
                let result = arrDates[0].split("-").reverse().join("/");
                return result;
            } catch (error) {
                console.log(error)
            }
            return "-";
        },
        periodEndTitle:function(){
            try {
                const arrDates = this.selectedWeek.split("|");
                // let stDat = new Date(arrDates[1]);
                // // let dateArray = new Array();
                // const year = stDat.getFullYear();
                // let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                //     stDat
                // );
                // let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(stDat);
                // let result = `${da}/${mo}/${year}`
                let result = arrDates[1].split("-").reverse().join("/");
                return result;
            } catch (error) {
                console.log(error)
            }
            return "-";
        },
        businessTitle: function(){
            const busTitle = this.businesses.find((item) => item.value == this.businessId)
            return busTitle?.text??""
        }
    },
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            file: [],
            path: "",
            arrayLoaded: [],
            serverErrors: {},
            loadingMultiCancel: false,
            vari:'&#xE53E;',
            lockButtons: true,
            updateBtns: 0,
            tableSelected: [],
            total: 10,
			search: '',
            businessLoading: false,
            businessId: PageStateManager.getState(this.$router.history.current.path, 'businessId')??'',
            businesses: [],

            typeSelected: PageStateManager.getState(this.$router.history.current.path, 'typeSelected')??'QUINCENAL',
            types: [{value: "QUINCENAL", text: "QUINCENAL"}, {value: "SEMANAL", text: "SEMANAL"}],

            selectedYear: PageStateManager.getState(this.$router.history.current.path, 'selectedYear')??new Date().getFullYear().toString(),
            years: [],

            selectedWeek: PageStateManager.getState(this.$router.history.current.path, 'selectedWeek')??'',
            weeks: [],
            weeksSearchPayroll:[],
            weeksUploadPayroll:[],


            lastPage: false,
            customersPage: 1,

            timeoutID: null,
            lockSearch: false,

            receipts: [
                {text: "Fiscal", value: "FISCAL"},
                {text: "Integradora", value: "INTEGRADORA"},
            ],

            //UPLOAD PAYROLL RESULT MODAL TEXT VALUES
            uploadResultCount: 0,
            uploadResultBusinessSelected: "",
            uploadResultStart: "",
            uploadResultEnd: "",
            uploadResultType: "",
            ///
            uploadResultData: {},

            formPayrollUpload:{
                business_id: PageStateManager.getState(this.$router.history.current.path, 'businessId')??'',
                payroll_type: PageStateManager.getState(this.$router.history.current.path, 'typeSelected')??'QUINCENAL',
                selectedWeek: PageStateManager.getState(this.$router.history.current.path, 'selectedWeek')??'',
                type: "",
                file: [],
            },

            formPayrollSearch: {
                business_id: PageStateManager.getState(this.$router.history.current.path, 'businessId')??'',
                payroll_type: PageStateManager.getState(this.$router.history.current.path, 'typeSelected')??'QUINCENAL',
                selectedWeek: PageStateManager.getState(this.$router.history.current.path, 'selectedWeek')??'',
            },

            payBtnData: {
                disabled:false,
                text: "Pagar",
                icon: "",
                textColor:'white',
                textColorLevel: "0",
                color: "primary",
                // click: this.payMulti,
                click: this.openModalPayFile,
                block:false,
                loading: false,
            },

            modalPayFileProps: {
				visible: false,
				width: '900',
				title: '',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				scrollable: true
			},
			
			cancelPayFileModal: {
                text: "Cancelar",
                icon: "mdi-close",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeModalPayFile()}
            },

            cancelUploadResultModal: {
                text: "Cancelar",
                icon: "mdi-close",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeModalUploadResult()}
            },

            cancelUploadPayrollModal:{
                text: "Cancelar",
                icon: "mdi-close",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeModalUploadPayroll()}
            },
            uploadPayFileModal: {
                text: "Cargar",
                icon: "mdi-tray-arrow-up",
				to: "",
				block: false,
				color: "primary",
                textColor: "",
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.uploadPayFile()}


                //  text: "Generar pdf",
                // icon: "mdi-progress-download",
                // iconClass: "flip",
				// to: "",
				// block: false,
                // loading: false,
                // color: "primary",
                // textColor: "primary",
                // textColorLevel: "0",
            },
			acceptPayFileModal: {
                text: "Aceptar",
                icon: "mdi-check",
				to: "",
				block: true,
				color: '',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.payFile()}
            },
            
            modalUploadResultProps:{
                visible: false,
				width: '700',
				title: 'Cargar recibos de nómina',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
            },


            modalSearchPayrollProps: {
				visible: false,
				width: '700',
				title: 'Estado de carga de recibos de nómina',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
            acceptSearchPayrollModal: {
                text: "Buscar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'white',
                textColorLevel: '0',
                classes: "justi-center",
				loading:false,
                click: ()=>{this.searchPayroll()}
            },

            acceptUploadPayrollModal: {
                text: "Continuar",
                icon: "mdi-arrow-right-thin",
				to: "",
				block: true,
				color: 'primary',
				textColor:'white',
                textColorLevel: '0',
                classes: "justi-center",
				loading:false,
                click: ()=>{this.uploadPayroll()}
            },

            acceptUploadResultModal: {
                text: "Guardar",
                icon: "mdi-content-save-outline",
				to: "",
				block: true,
				color: 'primary',
				textColor:'white',
                textColorLevel: '0',
                classes: "justi-center",
				loading:false,
                click: ()=>{this.savePayrollResult()}
            },


            modalFormatProps: {
				visible: false,
				width: '700',
				title: 'Pago de nóminas',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},

            modalUploadPayrollProps: {
				visible: false,
				width: '700',
				title: 'Cargar recibos de nómina',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},

            donwloadFormatModal:{
                text: "Descargar",
                loading: false,
                classes: "",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
                textColor:'primary',
                click: ()=>{this.downloadExample()}
            },
            acceptFormatModal: {
                text: "Regresar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'white',
                textColorLevel: '0',
                classes: "justi-center",
				loading:false,
                click: ()=>{this.modalFormatProps.visible = false}
            },

         
            rules: {

                required: [
					v => !!v || 'el campo es requerido'
				],
				
                file_required: [
                    v => {
                        if((v instanceof Array && v.length == 0) || v == "" || v == null)
                            return 'El archivo es requerido';
                        
                        return true;
                    }
                ],
            },
			
            actions: [
                // {
				// 	name: 'Detalle',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-information-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'redirect',   //  method, external, redirect
				// 	action: '',
                //     permissions: 'rrhh_paysheet.show'
				// },
				// {
				// 	name: 'Editar',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-pencil-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'redirect',   //  method, external, redirect
				// 	action: '/obras/1/edicion',
                //     permissions: 'rrhh_paysheet.update'
				// },

                {
					name: 'Pagar',
					icon: {
						color: 'secondary',
						icon: 'mdi-cash-check',
                       
					},
                    loading: false,
					disabled: true,
					type: 'method',   //  method, external, redirect
					action: 'paySingle',
                    permissions: 'payroll_payments.create'
				},
                {
					name: '',
					icon: {
						color: 'secondary',
						icon: 'mdi-chart-box-outline'
					},
                    loading: false,
					disabled: true,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'payroll_payments.show'
				},
                {
					name: 'Deshacer Pago',
					icon: {
						color: 'secondary',
						icon: 'mdi-history'
					},
                    loading: false,
					disabled: true,
					type: 'method',   //  method, external, redirect
					action: 'cancelSingle',
                    permissions: 'payroll_payments.delete'
				},
			],
			tableProps: {
				headers: [
                    {
						text: 'Número de\nEmpleado',
						align: 'left small-th-payroll',
						value: 'employee_number',
						width: "10",
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
					{
						text: 'Nombre',
						align: 'left small-th-payroll',
						value: 'name',
						
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
                        text: 'Sueldo\nfiscal',
						align: 'left small-th-payroll',
                        
						value: 'fiscal_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                     {
						text: 'Sueldo\nintegradora',
						align: 'left small-th-payroll',
                        
						value: 'integrated_salary',
                        class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                     {
						text: 'Costo total\nsueldo',
						align: 'left small-th-payroll',
                        
						value: 'total_cost_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Costo\nintegradora',
						align: 'left small-th-payroll',
                        
						value: 'integradora_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                   
                   
                    {
						text: 'Costo\ninfonavit',
						align: 'left small-th-payroll',
                        
						value: 'infonavit_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                     {
						text: 'Costo\nIMSS',
						align: 'left small-th-payroll',
                        
						value: 'ims_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                   

                    {
						text: 'Costo\nISN',
						align: 'left small-th-payroll',
                        
						value: 'isn',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
						text: 'Sueldo\ncontratado',
						align: 'left small-th-payroll',
                        
						value: 'signed_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
                        fixed: true,
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
					
				],
				items: [],
				search: '',
				loading: true,
                elevation: '0',
                showSelect: true,
                itemKey:"employee_id"
			},


            tableUploadProps: {
                headers: [
                    {
						text: 'Número de Empleado',
						align: 'left small-th-payroll',
						value: 'employee_number',
						
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
					{
						text: 'Empleado',
						align: 'left small-th-payroll',
						value: 'name',
						
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
                        text: 'Sueldo integradora',
						align: 'left small-th-payroll',
                        
						value: 'integradora_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                     {
						text: 'Sueldo fiscal',
						align: 'left small-th-payroll',
                        
						value: 'fiscal_salary',
                        class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    
					
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter:true,
            },


            tableFormatProps: {
				headers: [
                    {
						text: 'numero',
                        sortable: false,
						align: 'left',
						value: 'number',
						class: 'table-b-border'
					},
					{
						text: 'nombre',
                        sortable: false,
						align: 'center',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'integradora',
                        sortable: false,
						align: 'center',
						value: 'integ',
						class: 'table-b-border'
					},
                    {
						text: 'fiscal',
                        sortable: false,
						align: 'center',
						value: 'fis',
						class: 'table-b-border'
					},
                    
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: false,
                hideFooter:true,
                elevation: '0'
			},

            tableSearchPayloadProps: {
				headers: [
                    {
						text: 'Empleado',
                        sortable: false,
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
					{
						text: 'Fiscal',
                        sortable: false,
						align: 'center',
						value: 'has_fiscal_paycheck',
						class: 'table-b-border'
					},
                    {
						text: 'Integ',
                        sortable: false,
						align: 'center',
						value: 'has_integradora_paycheck',
						class: 'table-b-border'
					},
                   
                    
				],
				items: [],
				search: "",
				loading: false,
                hideFooter:true,
                elevation: '0'
			},

            tableUploadResultProps: {
				headers: [
                    {
						text: 'Empleado',
                        sortable: false,
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
					{
						text: 'check',
                        sortable: false,
						align: 'center',
						value: 'has_paycheck',
						class: 'table-b-border'
					},
                    
                   
                    
				],
				items: [],
				search: "",
				loading: false,
                hideFooter:true,
                hideHeaders:true,
                elevation: '0'
			},
			






			
		}
	},
    

    watch: {
        /**
         * Reset values on "type" change
         */
        [`businessId`]: function () {
            console.log(this.businessId)
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            PageStateManager.setState(this.$router.history.current.path, 'businessId', this.businessId);
            this.formPayrollUpload.business_id = this.businessId;
            this.formPayrollSearch.business_id = this.businessId;
            
        },
        selectedYear: function(v){
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            PageStateManager.setState(this.$router.history.current.path, 'selectedYear', this.selectedYear)
            this.selectedWeek = "";
            this.getWeeks(v);
            this.getWeeksSearchPayroll();
            this.getWeeksUploadPayroll();
        },

        typeSelected: function(){
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            PageStateManager.setState(this.$router.history.current.path, 'typeSelected', this.typeSelected);
            this.formPayrollUpload.payroll_type = this.typeSelected;
            this.selectedWeek = "";
            this.getWeeks(this.selectedYear);
            this.formPayrollSearch.payroll_type = this.typeSelected;
            this.formPayrollUpload.payroll_type = this.typeSelected;
            this.getWeeksSearchPayroll();
            this.getWeeksUploadPayroll();
        },
        selectedWeek: function(){
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            PageStateManager.setState(this.$router.history.current.path, 'selectedWeek', this.selectedWeek);
            this.formPayrollUpload.selectedWeek = this.selectedWeek;
            this.formPayrollSearch.selectedWeek = this.selectedWeek;
        },

        [`tableSelected`]: function(){
            this.lockButtons = true;
            // this.payBtnData.disabled = true;
            // if(this.tableSelected.length > 0){
            //     // this.payBtnData.disabled = false;
            //     // this.lockButtons = false;
            // }

            // const unlockPay = this.tableSelected.filter((item) => item.id == null)
            // console.log(unlockPay, "unlok");
            // if(unlockPay != undefined && unlockPay.length > 0)
            //     this.payBtnData.disabled = false;

            const unlockCancel = this.tableSelected.filter((item) => item.id != null)
            // console.log(unlockCancel, "unlok");
            if(unlockCancel != undefined && unlockCancel.length > 0)
                this.lockButtons = false;
            
            this.updateBtns = this.updateBtns + 1;
            
            console.log("ennt")
        },

        [`modalPayFileProps.visible`]: function (){
            try {
                this.$refs.form.reset();
            } catch (error) {
                console.log(error)
            }
            this.tableUploadProps.items = [];
            // this.file = [];
            this.arrayLoaded = [];
        },

         [`modalSearchPayrollProps.visible`]: function (){
            
            this.tableSearchPayloadProps.items = [];
           
        },

       
        
        // prevHeight: function(v){
        //     if(v != 0)
        //         this.prevHeight = 0;
        // }
    },
   
    
   

	mounted(){
        this.getYearSelect();
		this.getBusiness();
        this.getWeeks();
        this.getWeeksSearchPayroll();
        this.getWeeksUploadPayroll();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            // if(window.innerWidth <= 960){
            //     this.exportBtnData.block = true;
            //     this.pdfBtnData.block = true;

               
            // }
            // else{
                
            //     this.exportBtnData.block = false;
            //     this.pdfBtnData.block = false;
            // }
        },
        openUrl(fileUrl){
            if(fileUrl == null || fileUrl == "")
                return;
            window.open(fileUrl)
        },
        method_handler(object){
            console.log(object);
			this[object.method_name](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			console.log(object.options, "handreoptions run");
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            // this.index();
        },
		index() {
            this.cancel();
            this.lockButtons = true;
            // this.payBtnData.disabled = true;
            // // console.log(this.$router)
            if(this.businessId == '' || this.businessId == null || this.businessId == undefined)
                return;
            if(this.typeSelected == "" || this.typeSelected == null)
                return;
            if(this.selectedWeek == "" || this.selectedWeek == null)
                return;
			this.tableProps.loading = true;
           
            this.tableProps.items = [];
           
            const datesRequest = this.selectedWeek.split("|");
           
			//call api
			this.$api.payroll.indexPayrollPayments(this.businessId, this.typeSelected, datesRequest[0], datesRequest[1])
				.then((resp) =>{
					console.log(resp);
                   
					this.tableProps.items = resp.data.map((x,i) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));

                        x['parameters'] = {index: i, id: x.id, name: x.name, data:JSON.parse(JSON.stringify(x))};
                        x.index = i;
                        x.raw = JSON.parse(JSON.stringify(x))
                       
                        // x.actions[0].action = { name: 'PayrollDetails', params: { id: x.employee_id, } };
                        // x.actions[1].action = { name: 'PayrollEdit', params: { id: x.employee_id, } };

                        if(x.id != null && x.has_payment){
                            x.actions[0].disabled = true;
                            x.actions[2].disabled = false;
                        }

                        if(x.id == null && x.has_payment == false){
                            x.actions[0].disabled = false;
                        }

                        x.signed_salary = Utils.currencyFormat(x.signed_salary??0, "");
                        x.integradora_cost = Utils.currencyFormat(x.integradora_cost??0, "");
                        x.total_cost_salary = Utils.currencyFormat(x.total_cost_salary??0, "");
                        x.fiscal_salary = Utils.currencyFormat(x.fiscal_salary??0, "");
                        x.integrated_salary = Utils.currencyFormat(x.integrated_salary??0, "");
                        x.monthly_cost = Utils.currencyFormat(x.monthly_cost??0, "");
                        x.ims_cost = Utils.currencyFormat(x.ims_cost??0, "");
                        x.infonavit_cost = Utils.currencyFormat(x.infonavit_cost??0, "");
                        x.isn = Utils.currencyFormat(x.isn??0, "");
                      
                       
						return x;
					});
                   
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
                   
				})
			;
           
		},

        getBusiness()
        {
            
            this.businessLoading = true;
            this.$flujoApi.business.getBusinessSelectFiltered()
                .then((resp) => {
                    this.businesses = resp.data;

                    if(this.businessId == "" && this.businesses.length > 0){
                        this.businessId = this.businesses[0].value??"";
                    }
                    this.index();

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.businessLoading = false;
                })
            ;
        },

        getYearSelect()
        {
            // this.years.push({value:"2021", text: "2021"});
            // this.years.push({value:"2022", text: "2022"});
            // this.years.push({value:"2023", text: "2023"});
            // this.years.push({value:"2024", text: "2024"});
            // this.years.push({value:"2025", text: "2025"});
            this.years = [];
            let now = new Date();
            now.setHours(0,0,0,0)
            const year = now.getFullYear();
            console.log(year);
            if(this.selectedYear == "")
                this.selectedYear = ""+year;
            for (let i = 2022; i <= year; i++) {
                this.years.push({text: ""+i, value: ""+i});
            }
        },
        cancel() {
            this.tableSelected = [];
        },

        getWeeks()
        {
            const utils = new Utils();
            console.log(this.selectedYear)
            //actual
            if(this.typeSelected == "SEMANAL")
                this.weeks = utils.weeksYear(this.selectedYear);
            else
                this.weeks = utils.halfMonthsYear(this.selectedYear);
            console.log(this.weeks);
            const actual = this.weeks.filter(w => w.active == true)[0];
            
            if(actual != undefined && actual != null && this.selectedWeek == "")
            {
                this.actualWeek = actual.value;
                this.selectedWeek = actual.value;
                this.index()
            }
        },

        getWeeksSearchPayroll()
        {
            const utils = new Utils();
            //actual
            if(this.formPayrollSearch.payroll_type == "SEMANAL")
                this.weeksSearchPayroll = utils.weeksYear(this.selectedYear);
            else
                this.weeksSearchPayroll = utils.halfMonthsYear(this.selectedYear);
            console.log(this.weeksSearchPayroll);
            const actual = this.weeksSearchPayroll.filter(w => w.active == true)[0];
            
            if(actual != undefined && actual != null && this.formPayrollSearch.selectedWeek == "")
            {
                this.actualWeek = actual.value;
                this.formPayrollSearch.selectedWeek = actual.value;
                // this.index()
            }
        },

        getWeeksUploadPayroll()
        {
            const utils = new Utils();
            //actual
            if(this.formPayrollUpload.payroll_type == "SEMANAL")
                this.weeksUploadPayroll = utils.weeksYear(this.selectedYear);
            else
                this.weeksUploadPayroll = utils.halfMonthsYear(this.selectedYear);
            const actual = this.weeksUploadPayroll.filter(w => w.active == true)[0];
            
            if(actual != undefined && actual != null && this.formPayrollUpload.selectedWeek == "")
            {
                this.actualWeek = actual.value;
                this.formPayrollUpload.selectedWeek = actual.value;
                // this.index()
            }
        },


        sendMail()
        {

        },

        cancelMulti()
        {
            let dataReq = this.tableSelected.map((item) => {
                if(item.id != null) {
                    return {
                        id: item.id
                    }
                }
                
            });

            dataReq = dataReq.filter((item) => item != null);
            if(dataReq.length == 0){
                this.$store.dispatch('snackbarError', `No hay pagos en los elementos seleccionados`);
                return;
            }

            this.loadingMultiCancel = true;
            this.$api.payroll.cancelMultiplePayments({payments: dataReq})
			// eslint-disable-next-line
			.then((response) => {
				this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
				
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al modificar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
                this.loadingMultiCancel = false;
			})
        },

        pay(data, itemIndex = null, loadingMulti = false)
        {

            const datesRequest = this.selectedWeek.split("|");
            if(itemIndex != null){
                this.tableProps.items[itemIndex].actions[0].loading = true;
            }
            console.log(loadingMulti);
            if(loadingMulti){
                this.acceptPayFileModal.loading = true;
            }

            this.$api.payroll.setPayment(this.businessId, this.typeSelected, datesRequest[0], datesRequest[1], data)
			// eslint-disable-next-line
			.then((response) => {
				this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
				
				
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al modificar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
                if(itemIndex != null){
                    this.tableProps.items[itemIndex].actions[0].loading = false;
                }
                if(loadingMulti){
                    this.acceptPayFileModal.loading = false;
                    this.closeModalPayFile();
                }
                this.index();
			})
        },

        payMulti(){
            if(this.businessId == '' || this.businessId == null || this.businessId == undefined)
                return;
            if(this.typeSelected == "" || this.typeSelected == null)
                return;
            if(this.selectedWeek == "" || this.selectedWeek == null)
                return;


            let dataReq = this.tableSelected.map((item) => {
                if(item.id == null) {
                    return {
                        employee_id: item.raw.employee_id,
                        payroll_type: item.raw.payroll_type,
                        integradora_cost: item.raw.integradora_cost,
                        total_cost_salary: item.raw.total_cost_salary,
                        fiscal_salary: item.raw.fiscal_salary,
                        integrated_salary: item.raw.integrated_salary,
                        monthly_cost: item.raw.monthly_cost,
                        isn: item.raw.isn,
                        ims_cost: item.raw.ims_cost,
                        infonavit_cost: item.raw.infonavit_cost,
                        amortization_infonavit: item.raw.amortization_infonavit,
                        signed_salary: item.raw.signed_salary
                    }
                }
                
            });

            dataReq = dataReq.filter((item) => item != null);
            if(dataReq.length == 0){
                this.$store.dispatch('snackbarError', `Todos los seleccionados tienen pago hecho`);
                return;
            }

            this.pay({employees: dataReq}, null, true);
        },


        paySingle(params)
        {

            if(this.businessId == '' || this.businessId == null || this.businessId == undefined)
                return;
            if(this.typeSelected == "" || this.typeSelected == null)
                return;
            if(this.selectedWeek == "" || this.selectedWeek == null)
                return;
            
            const dataReq = [
                {
                    employee_id: params.data.employee_id,
                    payroll_type: params.data.payroll_type,
                    integradora_cost: params.data.integradora_cost,
                    total_cost_salary: params.data.total_cost_salary,
                    fiscal_salary: params.data.fiscal_salary,
                    integrated_salary: params.data.integrated_salary,
                    monthly_cost: params.data.monthly_cost,
                    isn: params.data.isn,
                    ims_cost: params.data.ims_cost,
                    infonavit_cost: params.data.infonavit_cost,
                    amortization_infonavit: params.data.amortization_infonavit,
                    signed_salary: params.data.signed_salary
                }
            ]

            this.pay({employees: dataReq}, params.index);

			
        },


        cancelSingle(parameters)
        {
            console.log(parameters, "parammmsss");
            this.tableProps.items[parameters.index].actions[2].loading = true;
            console.log(this.tableProps.items[parameters.index].actions[2])
            this.$api.payroll.cancelPayment(parameters.data.id)
			// eslint-disable-next-line
			.then((response) => {
				this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
				
				
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al modificar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
                this.tableProps.items[parameters.index].actions[2].loading = false;
                this.index()
			})
        },


        openSearchPayrollModal()
        {
            this.modalSearchPayrollProps.visible = true;
        },

        searchPayroll()
        {
            
            // return this.$refs.form.validate();
            if(this.$refs.formSearchPayroll.validate()){
                this.tableSearchPayloadProps.items = [];
                this.tableSearchPayloadProps.loading = true
                const datesRequest = this.formPayrollSearch.selectedWeek.split("|"); 
                this.$api.payroll.paychecks(this.formPayrollSearch.business_id, datesRequest[0], datesRequest[1], this.formPayrollSearch.payroll_type)
                    .then((resp) =>{
                        console.log(resp);
                    
                        this.tableSearchPayloadProps.items = resp.data.map((x) => {
                            
                            x.name = `${x.employee_number} - ${x.name}`
                            return x;
                        });
                    
                    })
                    .catch((error) =>{
                        console.log(error);
                    })
                    .finally(() => {
                        this.tableSearchPayloadProps.loading = false;
                    
                    })
                ;
            
            }
        },


        uploadPayroll()
        {
            if(this.$refs.formUploadPayroll.validate()){
                const dataRequest = this.prepareUploadPayrollRequest(this.formPayrollUpload);
                this.uploadResultData = dataRequest;
                this.acceptUploadPayrollModal.loading = true;
                this.tableUploadResultProps.items = [];
                this.$api.payroll.uploadPayroll(dataRequest)
                    .then((resp) =>{
                        console.log(resp);
                        this.uploadResultCount = resp.data.filter((itm) => itm.has_paycheck).length;
                        this.uploadResultBusinessSelected = this.businesses.find((bus) => bus.value == this.formPayrollUpload.business_id).text;
                        const datesRequest = this.formPayrollUpload.selectedWeek.split("|"); 
                        this.uploadResultStart = Utils.globalDateFormat(datesRequest[0]);
                        this.uploadResultEnd = Utils.globalDateFormat(datesRequest[1]);
                        this.uploadResultType = this.formPayrollUpload.type;

                        this.tableUploadResultProps.items = resp.data.map((x) => {
                            x.name = `${x.employee_number} - ${x.name}`
                            return x;
                        });
                        this.modalUploadPayrollProps.visible = false;
                        this.modalUploadResultProps.visible = true;
                    
                    })
                    .catch((error) =>{
                        console.log(error);
                    })
                    .finally(() => {
                        this.acceptUploadPayrollModal.loading = false;
                    
                    })
                ;
            
            }
        },
        prepareUploadPayrollRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            const datesRequest = data.selectedWeek.split("|"); 
            data[`start_date`] = datesRequest[0];
            data[`end_date`] = datesRequest[1];
            data[`paychecks`] = [];
            this.formPayrollUpload.file.forEach(f => {
                console.log(f)
                data[`paychecks`].push({file: f});
            });
            delete data.file;
            delete data.selectedWeek;
            return Object2Form.objectToFormData(data, "", []);

        },

        savePayrollResult()
        {
            this.acceptUploadResultModal.loading = true;
            this.$api.payroll.savePayroll(this.uploadResultData)
                    .then(() =>{
                        
                        this.modalUploadResultProps.visible = false;
                        this.$store.dispatch('snackbarSuccess', `Se ha guardado correctamente`);
                        this.index();
                    })
                    .catch((error) =>{
                        console.log(error);
                    })
                    .finally(() => {
                         this.acceptUploadResultModal.loading = false;
                    
                    })
                ;
        },


        openModalPayFile()
        {
            this.modalPayFileProps.visible = true;
        },


        closeModalPayFile()
        {
            this.modalPayFileProps.visible = false
        },

        closeModalUploadPayroll()
        {
            this.modalUploadPayrollProps.visible = false;
            this.formPayrollUpload.file = [];
        },

        closeModalUploadResult()
        {
            this.modalUploadResultProps.visible = false;
        },

        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},


        uploadPayFile()
        {
            console.log(this.file)
            if(this.validate()){

                if(this.businessId == '' || this.businessId == null || this.businessId == undefined)
                    return;
                if(this.typeSelected == "" || this.typeSelected == null)
                    return;
                if(this.selectedWeek == "" || this.selectedWeek == null)
                    return;
            
                const datesRequest = this.selectedWeek.split("|");
            
                let form = Object2Form.objectToFormData({file:this.file}, '', [])
               
                this.uploadPayFileModal.loading = true;
                this.$api.payroll.readPayFile(this.businessId, this.typeSelected, datesRequest[0], datesRequest[1], form)
                    .then((response) => {
                        console.log(response.data)
                        this.arrayLoaded = JSON.parse(JSON.stringify(response.data));
                        this.tableUploadProps.items = response.data.map((x) => {

                            x.raw = JSON.parse(JSON.stringify(x))
                            x.fiscal_salary = Utils.currencyFormat(x.fiscal_salary??0, "");
                            x.integradora_cost = Utils.currencyFormat(x.integradora_cost??0, "");
                        
                            return x;
                        });
                        // this.tableUploadProps.items.push(...this.tableUploadProps.items)
                        // this.tableUploadProps.items.push(...this.tableUploadProps.items)
                        // this.tableUploadProps.items.push(...this.tableUploadProps.items)
                        // this.tableUploadProps.items.push(...this.tableUploadProps.items)
                        // this.tableUploadProps.items.push(...this.tableUploadProps.items)
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                //console.log(99, error.response);
                                if(("error" in error.response.data)){
                                    Object.keys(error.response.data.error).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.error;
                                        error.response.data.error[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.uploadPayFileModal.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
    
        },

        payFile()
        {
            if(this.arrayLoaded.length == 0)
                return; 

            let dataReq = this.arrayLoaded.map((item) => {
                if(item.id == null) {
                    return {
                        employee_id: item.employee_id,
                        payroll_type: item.payroll_type,
                        integradora_cost: item.integradora_cost,
                        total_cost_salary: item.total_cost_salary,
                        fiscal_salary: item.fiscal_salary,
                        integrated_salary: item.integrated_salary,
                        monthly_cost: item.monthly_cost,
                        isn: item.isn,
                        ims_cost: item.ims_cost,
                        infonavit_cost: item.infonavit_cost,
                        amortization_infonavit: item.amortization_infonavit,
                        signed_salary: item.signed_salary
                    }
                }
                
            });

            dataReq = dataReq.filter((item) => item != null);
            if(dataReq.length == 0){
                this.$store.dispatch('snackbarError', `Todos los empleados de la lista tienen pago hecho`);
                return;
            }

            this.pay({employees: dataReq}, null, true);
        },

        openUploadPayrollModal()
        {
            this.modalUploadPayrollProps.visible = true;
        },

        openFormatModal()
        {
            
            this.modalFormatProps.visible = true;
            
           
            
        },

        downloadExample()
        {
            this.donwloadFormatModal.loading = true;
            this.$api.payroll.downloadFormatExample()
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `example_format.csv`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.donwloadFormatModal.loading = false;
                })
            ;
        },
        

        

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }

    .small-th-payroll{
        font-size: 0.66rem !important;
        padding: 4px 8px !important;
        @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
            font-size: 0.60rem !important;
            padding: 5px 5px !important;
        }
    }



    .custom-btns-container{
        display: flex;
        width: -webkit-fill-available;
        gap: 10px;
        @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
            flex-wrap: wrap;
            
        }
    }

    .filters-container{
        display: flex;
        flex: .7;
        gap: 5px;
        

        @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
            flex: 1 100%;

        }
         @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            flex-wrap: wrap;

        }

        .body-1{
            font-size: 0.8rem !important;

        }
        .v-input__control{
            font-size: 0.8rem !important;
        }

        &-item-1{
            flex: .4;
           
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
        &-item-2{
            flex: .2;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
        &-item-3{
            flex: .15;
             @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
        &-item-4{
            flex: .25;
             @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
       
    }

    .btns-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        flex: .4;
        text-align: end;
        @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
            flex: 1 100%;

        }
    }

    .upload-btn{
        margin-top: -2px;
    }


    .Payroll-modalPay-top-buttons-container{
        display: flex;
        justify-content: flex-end;
    }
    
    .Payroll-modalSearch-icon-mov{
        padding-left: 20px !important;
    }
</style>